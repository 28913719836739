import React,{useEffect,useState} from 'react';
import { Link, NavLink } from 'react-router-dom'
import axios from 'axios'
import { fetchAppAddress } from "../../layout/appRedux/appReduxAction"
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import QRCode from '../../app/components/QRComponent';

let Footer = () =>{
    let dispatch = useDispatch()
    let {REACT_APP_WEB_URL} = process.env
    let [appDownloadlinkPage,setAppDownloadlinkPage] = useState(`${REACT_APP_WEB_URL}/click-to-download-our-app`)

    let {footerAddress} = useSelector((state)=>({
        footerAddress:state.app.address
    }),shallowEqual)

    // useEffect(()=>{
    //     console.log("REACT_APP_WEB_URL",REACT_APP_WEB_URL)
    //     setAppDownloadlinkPage(`${REACT_APP_WEB_URL}/click-to-download-our-app`)
    // },[appDownloadlinkPage])

    useEffect(()=>{
        if(!footerAddress){
            dispatch(fetchAppAddress());
        }
    },[dispatch])

    return (
        <div className="footer">
            <div className="container footer_content">
                <div className="row">
                    <div className="col-md-3 text-center">
                        <h1>FindMech</h1>
                        <p>
                        Findmech is an innovative , safer, easier and convenient way to solve all your automotive problems by thoroughly
                        skilled mechanics with industry knowledge.
                        An app where you can buy, swap and sell your cars.
                        Get trusted and original spare parts by verified vendors.
                        </p>
                    </div>
                    <div className="col-md-3 text-center">
                        <h1>Address</h1>
                        <p>{footerAddress && footerAddress.address2}</p>
                        <p>{footerAddress && footerAddress.address}</p>
                        <p>{footerAddress && footerAddress.dialCode} {footerAddress && footerAddress.mobileNumber}</p>
                        <p>{footerAddress && footerAddress.email}</p>
                    </div>
                    <div className="col-md-2 text-center">
                        <h1>Quick Links</h1>
                        <ul className="footer_links">
                            <li>
                                <Link className="nav-link" to="/" >Home</Link>
                            </li>
                            <li>
                                <Link className="nav-link" to="/explore" >Explore</Link>
                            </li>
                            <li>
                                <Link className="nav-link" to="/about-us" >About</Link>
                            </li>
                            <li>
                                <Link className="nav-link" to="/contact-us" >Contact</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-2 text-center">
                        <h1>Follow Us</h1>
                        <div className='social_icons'>
                            <span className='social-icons'>
                                <a href="https://www.instagram.com/findmechsp/" target="_blank">
                                    <i className="fa fa-instagram"></i>
                                </a>
                            </span>
                            <span className='social-icons'>
                                <a href="https://twitter.com/Findmech1" target="_blank">
                                    <i className="fa fa-twitter"></i>
                                </a>
                            </span>
                            {/* <span className='social-icons'>
                                <a href="#" target="_blank">
                                    <i className="fa fa-linkedin"></i>
                                </a>
                            </span> */}
                            <span className='social-icons'>
                                <a href="https://www.facebook.com/profile.php?id=100084952760268" target={"_blank"}>
                                    <i className="fa fa-facebook"></i>
                                </a>
                            </span>
                            {/* <span className='social-icons'>
                                <a href="#" target="_blank">
                                    <i className="fa fa-play"></i>
                                </a>
                            </span> */}
                        </div>
                        {/* <div className='our-app-qr'>
                            <span className='qr-heading'>Download App</span>
                            <QRCode />
                        </div> */}
                    </div>
                    <div className="col-md-2 text-center">
                        <h1>Download Our App</h1>
                        <div className='our-app-qr'>
                            <QRCode  link={appDownloadlinkPage} />
                        </div>
                    </div>
                </div>
            </div>
            <div id="copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <p>Copyright@{new Date().getFullYear()} Findmech | All Rights Reserved </p>
                        </div>
                        <div className="col-md-6">
                            <p style={{"textAlign":"right"}}>
                                <a href="#">Privacy Policy</a> | <a href="#">Terms &amp; Conditions</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;
