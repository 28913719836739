import React,{ useState } from 'react';
import HeaderMobile from '../header-mobile/headerMobile'
// import HeaderProfile from '../../app/containers/Common/Profile/profile';
import { makeStyles } from '@material-ui/core';
import { NavLink,Link } from 'react-router-dom';
import { ucFirst } from '../_helpers';
import { Nav,Dropdown,Navbar,Container, NavDropdown } from 'react-bootstrap';
import { useLocation } from "react-router";
import { toAbsoluteUrl, checkIsActive,checkIsActiveMenu } from '../_helpers/index'
import { useDispatch,useSelector,shallowEqual } from  "react-redux";
import * as actions from "../../layout/appRedux/appReduxAction";
import {
    Login,
    Signup,
} from "../../app/containers/Auth";


let Header = () =>{
    const location = useLocation();
    let dispatch = useDispatch();

    const getMenuItemActive = (url) => {
        // console.log("checkIsActive(location, location",location)
        // console.log(location, url,checkIsActive(location, url))
        return checkIsActiveMenu(location, url) ? "active":"";
    };

    const userDetails = useSelector((state) => state.auth.user, shallowEqual);

    // const handleToggle = () => {
    //     setOpen((prevOpen) => !prevOpen);
    // };

    let openLoginPopup = ()=>{
        console.log("here")
        dispatch(actions.openHideLoginPopup({showLoginPopup:true,showSignupPopup:false}))
    }

    let hideLoginPopup = ()=>{
        console.log("here")
        dispatch(actions.openHideLoginPopup({showLoginPopup:false,showSignupPopup:false}))
    }

    let openSignupPopup = ()=>{
        dispatch(actions.openHideSignupPopup({showLoginPopup:false,showSignupPopup:true}))
    }

    let hideSignupPopup = ()=>{
        dispatch(actions.openHideSignupPopup({showLoginPopup:false,showSignupPopup:false}))
    }

    const  { showLoginPopup,showSignupPopup }  = useSelector((state)=>({
        showLoginPopup:state.app.showLoginPopup,
        showSignupPopup:state.app.showSignupPopup
    }),shallowEqual)

    console.log("showLoginPopup",showLoginPopup)

    return  (
        <>
            <div id="myHeader">
                <nav className="navbar navbar-expand-lg static-top bgblack">
                    <div className="container">
                        <a className="navbar-brand" href="index.html">
                            <img src="/media/images/logo.png" alt="..." height="36" />
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto">
                                <li className={`nav-item ${getMenuItemActive("/")}`}>
                                    <Link className={`nav-link textwhite`} aria-current="page" to="/"  >Home</Link>
                                </li>
                                <li className={`nav-item ${getMenuItemActive("/apps")} `}>
                                    <Link className={`nav-link textwhite ${getMenuItemActive("/apps")}`} aria-current="page" to="/apps">App</Link>
                                </li>
                                <li className={`nav-item ${getMenuItemActive("/explore")} `}>
                                    <Link className={`nav-link textwhite ${getMenuItemActive("/explore")}`} aria-current="page" to="/explore">Explore</Link>
                                </li>
                                <li className={`nav-item ${getMenuItemActive("/about-us")} `}>
                                    <Link className={`nav-link textwhite ${getMenuItemActive("/about-us")}`} aria-current="page" to="/about-us">About Us</Link>
                                </li>
                                <li className={`nav-item ${getMenuItemActive("/contact-us")} `}>
                                    <Link className={`nav-link textwhite ${getMenuItemActive("/contact-us")} `} aria-current="page" to="/contact-us">Contact</Link>
                                </li>
                                {
                                    !userDetails && (
                                        <>
                                        <li className={`nav-item ${getMenuItemActive("/auth/login")} `}>
                                            {/* <Link to={`/auth/login`} className={`nav-link ${getMenuItemActive("/auth/login")}`} aria-current="page">Login</Link> */}
                                            <Link to={`#`} onClick={openLoginPopup}  className={`nav-link textwhite`} aria-current="page">Login</Link>
                                        </li>
                                        <li className={`nav-item ${getMenuItemActive("/auth/signup")} `}>
                                            {/* <Link to={`/auth/signup`} onClick={openSignupPopup} className={`nav-link ${getMenuItemActive("/auth/signup")}`} aria-current="page">Sign Up</Link> */}
                                            <Link to={`#`} onClick={openSignupPopup}  className={`nav-link textwhite`} aria-current="page">Signup</Link>
                                        </li>
                                        </>
                                    )
                                }
                                {
                                    userDetails && (
                                        <>
                                        <li className={`nav-item ${getMenuItemActive("/category")} `}>
                                            <Link to={`/category`} className={`nav-link ${getMenuItemActive("/category")} textwhite`} aria-current="page">Find Mechanic</Link>
                                        </li>

                                        <li className={`nav-item ${getMenuItemActive("/logout")} `}>
                                            <Link to={`/logout`} className={`nav-link ${getMenuItemActive("/logout")} textwhite`} aria-current="page">Logout</Link>
                                        </li>
                                        </>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
            {
                showLoginPopup && (
                    <Login openLoginPopup={openLoginPopup} openSignupPopup={openSignupPopup} hideLoginPopup={hideLoginPopup} />
                )
            }
            {
                showSignupPopup && (
                    <Signup openSignupPopup={openSignupPopup} openLoginPopup={openLoginPopup} hideSignupPopup={hideSignupPopup} />
                )
            }
        </>
    )
}

export default Header;
